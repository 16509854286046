.shopping-cart {
    // border: 4px solid olive;
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 0;

    .title-container {
        background-color: #bc3b3f;
        color: #ffffff;
        padding: 5px 0;
        cursor: pointer;
        width: 60%;
        margin: 0 auto;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        transition: all .4s ease-in-out;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &--expand {
            width: 100%;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;    
        }

        &--green-color {
            background-color: green;
        }

        .shopping-cart-icon {
            width: 50px;
            height: 50px;
        }

        .products-counter {
            position: absolute;
            left: calc(50% + 20px);
            bottom: 30px;
        }



        .title {
            padding: 10px 0;
        }

        .cart-open-title-enter {
            opacity: 0;
            transform: scale(0);
            transition: all 300ms ease-in-out;
        }

        .cart-open-title-enter-active {
            opacity: 1;
            transform: scale(1);
            transition: all 300ms ease-in-out;
        }

        .cart-open-title-exit {
            opacity: 1;
            transform: scale(1);
        }

        .down-arrow-icon {
            position: absolute;
            left: 20px;
            width: 25px;
        }
    }

    .products-list-wrapper {
        height: 0;
        transition: height .4s ease-in-out;
        overflow-y: scroll;
        
        &--expand {
            height: 30vh;
        }
    }

    .products-list {
        display: grid;
        background-color: white;
        grid-template-columns: repeat(auto-fill, 20%);
        align-items: center;
        padding: 10px;
        grid-row-gap: 5px;
        
        &__item {
            // border: 1px solid;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            
            img {
                width: 70%;
            }

            .quantity {
                position: absolute;
                color: white;
            }
        }
    }
}


.transition-item-enter {
    transform: scale(0);
    opacity: 0;
  }
  .transition-item-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: all 300ms ease-in;
  }
  .transition-item-exit {
    opacity: 1;
    transform: scale(1);
  }
  .transition-item-exit-active {
    opacity: 0;
    transform: scale(0);
    transition: all 300ms ease-in;
  }
  