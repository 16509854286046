.price-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
    color: #0166b5;

    .price {
        margin-bottom: 5px;
        font-size: 25px;
        font-weight: bold;

    }

    .currency {
        font-size: 16px;
        position: relative;
        right: 5px;
    }

    .pennies {
        font-size: 16px;
        position: relative;
        bottom: 8px;
    }

    .unit {
        font-size: 16px;
        font-weight: bold;
    }
}

