.swiper-container {
    height: 40%;

    .swiper-slide-active {
        // border: 2px solid green;

        .product-img {
            transform: scale(1) !important;
        }

        button {
            z-index: 1;
            opacity: 1 !important;
            transform: scale(1) !important;
            outline: none;
        }
    }

    .swiper-slide {
        .slide-img-container {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            transition: all .1s;
        }

        &--animate {
            animation-name: adding-item-to-cart;
            animation-duration: 1s;
        }

        @keyframes adding-item-to-cart {
            50% {opacity: 0; transform: scale(0) translateY(400px)}
            75% {opacity: 0; transform: scale(0) translateY(0px)}
            100% {opacity: 1; transform: scale(1)}
        }

        .product-img {
            width: 100%;
            transition: transform .3s ease-in-out;
            transform: scale(.6);
        }

        .quantity {
            position: absolute;
            color: white;
            font-size: 50px;
        }

        button {
            font-size: 70px;
            font-weight: 300;
            position: absolute;
            transition: all .6s;
            opacity: 0;
            transform: scale(0);
        }
    
        .decrease-btn {
            left: 0;
        }
    
        .increase-btn {
            right: 0;
        }
    }

    .single-slide {
        // border: 1px solid;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
}

.animation {
    border: 1px solid red !important;
}