.product-main-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 40px;
    /* border: 1px solid; */

}

.product-name {
    margin-bottom: 5px;
}

.product-brand {
    font-size: 18px;
}

