html {
    font-size: 1px;/*for using REM units*/
}

body {
    font-size: 20rem;
}

h1 {
    font-size: 30rem;
}

h2 {
    font-size: 25rem;
}